/**
 *
 * PluginIcon
 *
 */

import React from 'react'
import { Calendar, Puzzle } from '@strapi/icons'

const PluginIcon: React.VoidFunctionComponent = () => <Calendar />

export default PluginIcon
