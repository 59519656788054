/**
 *
 * PluginIcon
 *
 */

import React from 'react'
import { File } from '@strapi/icons'

const PluginIcon: React.VoidFunctionComponent = () => <File />

export default PluginIcon

//Write
//File
