import { prefixPluginTranslations } from '@strapi/helper-plugin'
import pluginPkg from '../../package.json'
import pluginId from './pluginId'
import Initializer from './components/Initializer'
import getTrad from './utils/getTrad'

const name = pluginPkg.strapi.name

export default {
    register(app: any) {
        app.customFields.register({
            name: 'contract',
            pluginId: 'create-contract',
            type: 'string',
            intlLabel: {
                id: getTrad('create-contract.label'),
                defaultMessage: 'Show contract',
            },
            intlDescription: {
                id: getTrad('create-contract.description'),
                defaultMessage: 'Press button to see contract',
            },
            components: {
                Input: async () =>
                    import('./pages/App').then((module) => ({
                        default: module.default,
                    })),
            },
        })

        const plugin = {
            id: pluginId,
            initializer: Initializer,
            isReady: false,
            name,
        }

        app.registerPlugin(plugin)
    },

    bootstrap(app: any) {},

    async registerTrads(app: any) {
        const { locales } = app

        const importedTrads = await Promise.all(
            (locales as any[]).map((locale) => {
                return import(`./translations/${locale}.json`)
                    .then(({ default: data }) => {
                        return {
                            data: prefixPluginTranslations(data, pluginId),
                            locale,
                        }
                    })
                    .catch(() => {
                        return {
                            data: {},
                            locale,
                        }
                    })
            })
        )

        return Promise.resolve(importedTrads)
    },
}
