/**
 *
 * PluginIcon
 *
 */

import React from 'react'
import { ChartPie } from '@strapi/icons'

const PluginIcon: React.VoidFunctionComponent = () => <ChartPie />

export default PluginIcon
