/**
 *
 * PluginIcon
 *
 */

import React from 'react'
import { Briefcase } from '@strapi/icons'

const PluginIcon: React.VoidFunctionComponent = () => <Briefcase />

export default PluginIcon
